import React from "react";
import styled from "styled-components";
import { Link } from 'gatsby'

const FooterContainer = styled.div `
    position: relative;
    border-radius: 0 0 0 0 ;
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.1); 
    width:100%
    background: white;
    font-size: 15px;
    line-height: 24px;
    
`

const ContentWrapper = styled.section `
    max-width: 840px;
    padding: 40px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    article {
        padding: 0 32px 60px 0;
        display: flex;
        flex-direction: column;

        &:last-child {
            padding-bottom: 0;
        }

    }

    span p {
        font-weight: 800;
        padding-bottom: 8px;
    }

   p {
       color: black;
       font-weight: 500;
       padding: 0;
       margin: 0
   }
   a {
       color: #B4B4B4;
       cursor: pointer;
       transition: .5s cubic-bezier(0.2, 0.8, 0.2, 1);
       font-weight: 500;


       &:hover {
        color: black;
        transition: color 1000ms linear;
       }
   }
`
const BR = styled.div `
height: 16px;
width: 1px;
`


const Footer = props => (
    <FooterContainer>
        <ContentWrapper>
            <article>
                <span><p>Ncell</p></span>
                <a href="https://www.google.com/maps/place/Ncell+Systems+Inc/@44.9811314,-93.3755336,12.22z/data=!4m8!1m2!2m1!1sNcell!3m4!1s0x0:0x33fc68d37826dc89!8m2!3d44.9719787!4d-93.4861422" alt="">1907 Wayzata Blvd <br />Suite 230<br />Wayzata, MN 55391</a>
                <BR />
                <a href="tel:952-746-5125">(952) 746-5125</a>
                <BR />
                <a href="mailto:info@ncell.com">info@ncell.com</a>
            </article>
            <article>
            <span><p>Mx</p></span> 
                <Link to="/mx">Manager</Link> 
                <Link to="/mxmrp">MRP</Link> 
                <Link to="/mxstation">Station</Link> 
                <Link to="/mxticket">Ticket</Link> 
                <Link to="/mxreport">Report</Link> 
            </article>
            <article>
            <span><p>Automation</p></span> 
            <Link to="/imanufacture">iManufacturing</Link> 
            <Link to="/mcstatus">MCStatus</Link> 
            </article>
            <article>
                <span><p>Nesting</p></span> 
                <Link to="/accucam">AccuCAM</Link> 
                <Link to="/accufab">AccuFAB</Link> 
            </article>
            
           
        </ContentWrapper>
    </FooterContainer>
)


export default Footer