import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import './Header.css'


class Header extends React.Component {
  constructor (props) {
    super(props)

    this.state= {
      hasScrolled: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 150) {
      this.setState({ hasScrolled: true})
    } else {
      this.setState({ hasScrolled: false })
    }
  }
  render (){
    return(
      <div className={this.state.hasScrolled ? 
        'Header HeaderScrolled' : 'Header'}>
        <div className="headerGroup">
          <Link to="/"><img src={require ('../images/logo-ncell.svg')} alt='Ncell logotype' width="80"></img></Link>
          <Link to="/mx">MX</Link>
          <Link to="/nesting">Nesting</Link>
          <Link to="/imanufacture">iManufacture</Link>
          <Link to="/mcstatus">MCStatus</Link>
          <Link to="/contact"><button>Contact Us</button></Link>
        </div>
      </div>
  
    )
  }
}




Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

